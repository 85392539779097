<template>
  <section class="user-profile-dashobard">
    <div v-loading="loading">
      <div>
        <el-row type="flex">
          <el-col class="navi-steps-left">
            <div class="sidebar-navigation" v-if="!loading">
              <div class="menu-list">
                <el-collapse
                  class="menu-scrollable"
                  v-model="userProfileSideMenu"
                  accordion
                >
                  <el-collapse-item :title="getEntityDataById.name" name="1">
                    <div class="sub-menu-line"></div>
                    <div
                      class="templates-list"
                      v-if="getAllSteps && getAllSteps.length"
                    >
                      <div
                        index="1"
                        v-for="(step, index) of getAllSteps"
                        :key="index"
                        :class="
                          activeStep(step) ? 'nav-link active' : 'nav-link'
                        "
                      >
                        <router-link :to="getStepUrl(step)">
                          <span>{{
                            step.senderAlias
                              ? step.senderAlias
                              : step.templateInfo.name
                          }}</span>
                        </router-link>
                      </div>
                    </div>
                    <div v-else>No Data</div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </el-col>
          <el-col>
            <div class="main-content-block">
              <router-view
                :layout.sync="layout"
                :key="$route.fullPath"
              ></router-view>
            </div>
            <div></div>
          </el-col>
        </el-row>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import NavigationHelper from "@/mixins/navigationHelper";
export default {
  name: "Entity-ViewEntity",
  components: {},

  mixins: [NavigationHelper],
  data() {
    return {
      loading: false,
      step: 1,
      slug: null,
      layout: "div",
      userId: "",
      userProfileSideMenu: ["1"],
    };
  },
  mounted() {
    this.getData();
  },

  created() {},
  computed: {
    ...mapGetters("entities", ["getEntityDataById"]),
    getAllSteps() {
      return this.getEntityDataById.templates;
    },

    getEntity() {
      return this.getEntityDataById;
    },

    getStepUrl() {
      return (step) => {
        return `/entity/ABC/${step.templateInfo.slug}`;
      };
    },

    activeStep() {
      return (step) => {
        if (this.$route.params.template_code === step.slug) {
          return true;
        }
        return false;
      };
    },
  },
  methods: {
    async gotoStep(step) {
      console.log(step);

      // let query = this.getNavigationQuery(this.$route.query);
      // this.$router.push({ path: this.getStepUrl(step),query:query });
    },
    gotoNextStep() {
      const code = this.$route.params.template_code;
      const currentStepIndex = this.getAllSteps.findIndex(
        (x) => x.slug == code
      );
      if (
        currentStepIndex > -1 &&
        currentStepIndex < this.getAllSteps.length - 1
      ) {
        this.gotoStep(this.getAllSteps[currentStepIndex + 1]);
      }
    },
    async backToEntityList() {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        path: "/entity",
        query: query,
      });
    },
    async getData() {
      this.loading = true;
      let id = this.$route.params.entity_id;
      await this.$store.dispatch("entities/fetchEntityById", {entity_id: id});

      if (this.getEntityDataById) {
        this.entityName = this.getEntityDataById.name;
        this.description = this.getEntityDataById.description;
      }

      if (this.getAllSteps && this.getAllSteps.length) {
        if (!this.$route.params.template_code) {
          this.gotoStep(this.getAllSteps[0]);
        }
      }

      this.loading = false;
    },
  },
  watch: {
    "$route.query.step"(step) {
      this.step = step;
    },
    "$route.params.slug"(slug) {
      this.slug = slug;
    },
  },
  // beforeDestroy() {
  //   this.$store.commit("entities/setEntityDataById",null,{root:true})
  // }
};
</script>

<style lang="scss" scoped>
.user-profile-box {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  grid-gap: 0.5em;
  margin-top: 2em;
  .profile-pic {
    &.circle {
      img {
        border-radius: 50%;
      }
    }
    img {
      max-width: 100%;
    }
  }
}
.user-profile-dashobard {
  margin-top: -30px;
  margin-right: -30px;
  .navi-steps-left {
    max-width: 300px;
    min-width: 200px;
    padding-top: 1.5em;
    .profile-card {
      background: #fdfdfd;
      border: 1px solid #f1f1f1;
      border-radius: 4px;
      text-align: center;
      padding: 1.5em 1em;
      img {
        border-radius: 50%;
        margin-bottom: 1em;
        border: 2px solid #a8abff;
      }
    }
  }
  .main-content-block {
    padding: 2em;
    ::v-deep .preview-template-view {
      .preview-form-header {
        margin-bottom: 1.5em !important;
      }
      .footer-action-block {
        .el-button {
          margin: 0em 0 0 auto;
        }
      }
      .form-group {
        label {
          color: #5e6d82 !important;
        }
        p {
          margin-top: 0px;
        }
      }
    }
  }
  .sidebar-navigation {
    margin-top: 20px;
    position: sticky;
    top: 100px;
    width: auto;
    .sub-menu-line {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 11px;
      width: 1.5px;
      background-color: #e0e0e0;
      z-index: 9;
    }
    .templates-list {
      position: relative;
      padding: 10px 0px;
      height: 200px;
      overflow-y: scroll;
      .nav-link {
        a {
          position: relative;
          color: #999a9d;
          font-size: 15px;
          display: block;
          height: 48px;
          line-height: 48px;
          margin-bottom: 0.5em;
          border-radius: 0 15px 15px 0;
          padding-left: 2em;
          z-index: 10;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 10px;
            width: 6px;
            background-color: #5096f1;
            opacity: 0;
            transition: 0.3s all ease-in-out;
            -webkit-transition: 0.3s all ease-in-out;
          }
          &:hover {
            color: #1b487e;
            background-color: #f6f6f6;
            z-index: 8;
          }
          .step-edit,
          .step-unfill,
          .step-default,
          .step-success {
            float: right;
            font-size: 1.25em;
            padding-top: 3px;
          }
          .step-success {
            color: rgb(103, 206, 0);
          }

          .step-unfill {
            color: rgb(214, 214, 52);
          }

          .step-edit {
            color: blue;
          }

          .step-default {
            color: #999999;
          }
        }
        .router-link-active {
          color: #1b487e;
          background-color: #ffffff;
          box-shadow: -5px 5px 10px #f9f9f9;
          &:hover {
            z-index: 11;
            background-color: #ffffff;
          }
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.user-profile-dashobard {
  .sidebar-navigation {
    .menu-scrollable {
      .el-button {
        font-family: "Inter", sans-serif;
        display: block;
        width: 100%;
        font-size: 15px;
        padding: 0px 1em 0px 1.25em;
        border-radius: 0 15px 15px 0;
        line-height: 48px;
        height: 48px;
        color: #303133;
        text-align: left;
        i {
          line-height: 48px;
        }
        & + .el-button {
          margin-left: 0;
        }
        &.active,
        &:focus {
          background-color: #e2edfc !important;
          color: #0f56b3;
        }
      }
    }
    .el-collapse {
      background-color: transparent;
      border-top: none;
      border-bottom: none;
      .el-collapse-item {
        position: relative;
        border-radius: 0 15px 15px 0;
        border-bottom: none;
        background-color: transparent;
        .el-collapse-item__arrow.is-active {
          background-color: transparent;
        }
        .el-collapse-item__content {
          padding-bottom: 0;
        }
        .el-collapse-item__header {
          font-size: 15px;
          background-color: transparent;
          position: relative;
          z-index: 10;
        }
        &.is-active {
          background-color: transparent !important;
          .el-collapse-item__header {
            background-color: #e2edfc !important;
            color: #0f56b3;
          }
        }
        .el-collapse-item__header {
          padding: 0px 1em 0px 1.25em;
          border-radius: 0 15px 15px 0;
          border-bottom: none;
        }
        .el-collapse-item__wrap {
          background-color: transparent;
          border-bottom: none;
        }
      }
    }
  }
}
</style>